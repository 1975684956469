<template>
  <section class="mt-4 pb-5">
    <div class="container">
      <div class="inner_page_two_sides">

        <div class="right_side">

          <div class="section_payment_breadcrumbs">
            <span><a href="/">{{ $t('common.main_page') }}</a></span>
            <span>{{ $t('common.contacts') }}</span>
          </div>

          <div class="section_header mt-3 mb-3">
            <span>{{ $t('common.contacts') }}</span>
          </div>

          <div class="section_content section_content_partners_monitoring">
            <span>
              <h5>{{ $t('common.forums') }}:</h5>
              <a href="https://forum.bits.media/index.php?/topic/179928-momentexpress-%D0%B0%D0%B2%D1%82%D0%BE%D0%BC%D0%B0%D1%82%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B9-%D0%BE%D0%B1%D0%BC%D0%B5%D0%BD%D0%BD%D0%B8%D0%BA-%D0%BA%D1%80%D0%B8%D0%BF%D1%82%D0%BE%D0%B2%D0%B0%D0%BB%D1%8E%D1%82-%D0%B8-%D1%8D%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D1%8B%D1%85-%D0%B4%D0%B5%D0%BD%D0%B5%D0%B3/"
                 rel="nofollow"
                 style="margin:0 0 0 5px;color: #7ca7c8!important;text-decoration: underline;" target="_blank">BITS.MEDIA</a>&nbsp;
              <a href="http://forumbusiness.net/showthread.php?p=272731#post272731" rel="nofollow"
                 style="margin:0 0 0 5px;color: #7ca7c8!important;text-decoration: underline;" target="_blank">FORUM BUSINESS</a>&nbsp;
              <a href="https://bitalk.org/threads/32316/" rel="nofollow" style="margin:0 0 0 5px;color: #7ca7c8!important;text-decoration: underline;" target="_blank">BITALK</a>&nbsp;
            </span>
            <br/>
            <br/>

            <span>
              <h5 style="color: #24415B;">
                  {{ $t('common.monitorings') }}:
              </h5>
              <a v-if="locale === 'en'" href="https://www.bestchange.com" target="_blank"><img src="https://www.bestchange.net/bestchange.gif" title="BestChange" alt="BestChange" width="88" height="31" border="0"></a>
              <a v-else href="https://www.bestchange.ru/moment-exchanger.html" target="_blank"><img src="https://www.bestchange.net/bestchange.gif" title="Обмен QIWI, Bitcoin, Tether, AdvCash" alt="Мониторинг обменных пунктов BestChange" width="88" height="31" border="0"></a>
              <a href="https://kurs.expert/ru/obmennik/moment-express/feedbacks.html#reputation" target="_blank"><img src="https://kurs.expert/i/buttonY.png" width="88" height="31" border="0" alt="Мониторинг обменных пунктов Курс-Эксперт"></a>
              <a href="https://www.okchanger.ru/exchangers/Moment-Express" target="_blank"><img src="https://www.okchanger.ru/images/banners/90x32.png" alt="Мониторинг обменных пунктов OkChanger"></a>
              <a href="https://glazok.org/exchange/?details=974" target="_blank"><img border="0" src="https://glazok.org/88x31.gif" width="88" height="31" alt="Мониторинг обменных пунктов GlazOk"></a>
              <a href="https://wellcrypto.io/ru/exchangers/" target="_blank"><img src="https://wellcrypto.io/i/b_2.svg" width="88" height="31" border="0" alt="wellcrypto.io"/></a>
              <a href="https://exnode.ru/" target="_blank"><img src="https://exnode.ru/photo/logo.jpg" width="88" height="31" border="0" alt="exnode.ru"/></a>
              <a href="https://multirates.org/exchangers/moment-express" target="_blank"><img src="https://multirates.org/banner8831.png" width="88" height="31" border="0" alt="Multirates.org"/></a>
            </span>
            <br/>
            <br/>

            <span>
              <h5 style="color: #24415B">
                   {{ $t('common.email') }}:
              </h5>
              <a href="mailto:info@moment.express" style="color: #7ca7c8!important;text-decoration: underline;" rel="nofollow">
                  info@moment.express
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      locale: 'ru'
    }
  },

  mounted() {
    this.locale = localStorage.getItem('user-locale');
  }
}
</script>
